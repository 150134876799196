import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { CheckCircle } from 'react-feather';

const Thanks = () => {

    return (
        <Layout>
            <Seo title="Thanks" />
            <section className="successPageWrapper">
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center">
                            <h2 className='uppercase mb-4'>Request A Demo</h2>
                            <p className="successPagePanel">
                                <CheckCircle />
                                Thanks, We'll be in touch soon
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Thanks
